import { create } from "vest";

import {
  inputIsNotEmpty,
  inputNoSlash,
  inputNoWhitespaces,
  valueMustBeNumber,
} from "@/utils/formValidation/formValidation";

import { AddNewVariablesBatchPropsForm } from "./types";

const schema = create((data: AddNewVariablesBatchPropsForm) => {
  data.variables.forEach((variable, index) => {
    inputIsNotEmpty(`variables.${index}.variableName`, variable.variableName);
    inputNoSlash(`variables.${index}.variableName`, variable.variableName);
    inputNoWhitespaces(
      `variables.${index}.variableName`,
      variable.variableName
    );
    inputIsNotEmpty(`variables.${index}.type`, variable.type);
    valueMustBeNumber(`variables.${index}.type`, variable.type);
  });
});

export default schema;
