import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { VariableProps } from "@/api/variablesSlice/types";
import { DataGrid } from "@/components";
import { useGetVariables } from "@/queries/variablesQuery/variablesQuery";
import { RECEIVING_TYPE } from "@/utils/consts";
import { usePlantVariablesContext } from "@/utils/PlantVariablesProvider";
import { variableConfigurationExist } from "@/utils/variableConfigValidation";

import usePlantVariableColumns from "./usePlantVariableColumns";

type PlantVariableTableProps = {
  variableId: string;
  variableName: string;
  maxAge: number | null;
  minLimit: number | null;
  maxLimit: number | null;
  invalidMeasurementHandling: number | null;
  expression: string;
  expressionInput: any;
  type: number;
};

const PlantVariableTable = () => {
  const { organizationId, resourceId } = useParams();
  const { setPlantVariablesToEdit } = usePlantVariablesContext();

  const { data: resourceVariablesData, isPending } = useGetVariables({
    organizationId,
    resourceId,
  });

  const columnsDef = usePlantVariableColumns({ organizationId, resourceId });

  const mapVariable = (variable: VariableProps): PlantVariableTableProps => {
    if (variable.configuration?.type === RECEIVING_TYPE.output) {
      return {
        variableId: variable.variableName,
        variableName: variable.variableName,
        invalidMeasurementHandling: null,
        maxLimit: null,
        minLimit: null,
        expression: "",
        expressionInput: {},
        type: variable.configuration.type,
        maxAge: null,
      };
    }

    return {
      variableId: variable.variableName,
      variableName: variable.variableName,
      invalidMeasurementHandling: variable.configuration
        ? variable.configuration.invalidMeasurementHandling
        : null,
      maxAge: variable.configuration ? variable.configuration.maxAge : null,
      expression: variable.configuration
        ? variable.configuration.expression
        : "",
      expressionInput: variable.configuration
        ? variable.configuration.expressionInput
        : {},
      type: variable.configuration ? variable.configuration.type : 0,
      maxLimit: variable.configuration ? variable.configuration.maxLimit : null,
      minLimit: variable.configuration ? variable.configuration.minLimit : null,
    };
  };

  const data = useMemo(() => {
    return resourceVariablesData
      ? resourceVariablesData
          .filter((variable) => variableConfigurationExist(variable))
          .map(mapVariable)
      : [];
  }, [resourceVariablesData]);

  const handleUpdateSelectedRows = (event: SelectionChangedEvent) => {
    // Filter out the output variables as they cannot be edited in bulk
    const selectedRows =
      event.api
        .getSelectedRows()
        .filter((row) => Number(row.type) !== RECEIVING_TYPE.output) ?? [];

    setPlantVariablesToEdit(selectedRows.map((row) => row.variableId));
  };

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columnsDef,
      rowSelection: "multiple",
      onSelectionChanged: handleUpdateSelectedRows,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
    }),
    [columnsDef]
  );

  return (
    <DataGrid rowData={data} gridOptions={gridOptions} loading={isPending} />
  );
};

export default PlantVariableTable;
