import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Button from "@/components/Button";
import { useDialog } from "@/components/Dialog/DialogProvider";
import AddNewVariables from "@/components/Forms/AddOutputVariables/AddNewVariables";
import DeleteMultipleVariablesDialog from "@/components/Forms/DeleteMultipleVariablesDialog/DeleteMultipleVariablesDialog";
import Icon from "@/components/Icon";
import OutputPlantVariableTable from "@/components/OutputPlantVariableTable/OutputPlantVariableTable";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { useOutputPlantVariablesContext } from "@/utils/OutputPlantVariableProvider";

import style from "./styles.module.scss";

const OutputPlantVariableTab = () => {
  const { organizationId, resourceId } = useParams();
  const { t } = useTranslation();
  const { showDialog } = useDialog();
  const { plantOutputVariablesToEdit } = useOutputPlantVariablesContext();

  return (
    <div>
      <div className={style.buttonsGroup}>
        <Button
          name="delete"
          variant="secondary"
          disabled={!(plantOutputVariablesToEdit.length > 0)}
          onClick={() =>
            showDialog(
              <DeleteMultipleVariablesDialog
                organizationId={organizationId}
                resourceId={resourceId}
                variables={plantOutputVariablesToEdit}
              />,
              "medium"
            )
          }
        >
          <Icon slot="prefix" name="trash"></Icon>

          {t(LangKeys.DELETE_VARIABLES)}
        </Button>

        <Button
          name="add"
          onClick={() =>
            showDialog(
              <AddNewVariables
                organizationId={organizationId}
                resourceId={resourceId}
              />,
              "medium"
            )
          }
        >
          <Icon slot="prefix" name="plus"></Icon>

          {t(LangKeys.ADD_NEW_VARIABLE)}
        </Button>
      </div>

      <OutputPlantVariableTable />
    </div>
  );
};

export default OutputPlantVariableTab;
