import i18n from "i18next";
import { enforce, test } from "vest";

import { LangKeys } from "../i18n/languageKeys";

export const inputIsNotEmpty = (inputId: string, value: string | number) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_REQUIRED), () => {
    enforce(value).isNotEmpty();
  });
};

export const inputNoUpperCase = (inputId: string, value: string | number) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_SMALL_SIGNS), () => {
    enforce(value).matches(/^[^A-Z]+$/);
  });
};

export const valueMustBeNumber = (inputId: string, value: any) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_MUST_BE_NUMBER), () => {
    enforce(value).isNumeric();
  });
};

export const inputNoWhitespaces = (inputId: string, value: string | number) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_WITHOUT_SPACES), () => {
    enforce(value).matches(/^\S+$/);
  });
};

export const inputNoSlash = (inputId: string, value: string | number) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_WITHOUT_SLASHES), () => {
    enforce(value).matches(/^[^/]+$/);
  });
};

export const inputMustBeEqualOrMoreThan = (
  inputId: string,
  value: number,
  compareValue: number
) => {
  test(
    inputId,
    i18n.t(LangKeys.VALIDATION_EQUAL_OR_MORE_THAN, { value: compareValue }),
    () => {
      enforce(value).greaterThanOrEquals(compareValue);
    }
  );
};

export const inputMustBeEqualOrLessThan = (
  inputId: string,
  value: number,
  compareValue: number
) => {
  test(
    inputId,
    i18n.t(LangKeys.VALIDATION_EQUAL_OR_LESS_THAN, { value: compareValue }),
    () => {
      enforce(value).lessThanOrEquals(compareValue);
    }
  );
};

export const inputMustBeNumber = (inputId: string, value: any) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_MUST_BE_NUMBER), () => {
    enforce(value).isNumeric();
  });
};

export const rolesMustBeSelected = (inputId: string, value: any) => {
  test(inputId, i18n.t(LangKeys.VALIDATION_ROLE_REQUIRED), () => {
    enforce(value).isNotEmpty();
  });
};
