import { SelectionChangedEvent } from "ag-grid-community";
import { GridOptions } from "ag-grid-community/dist/types/core/entities/gridOptions";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { VariableProps } from "@/api/variablesSlice/types";
import { useGetOutputVariables } from "@/queries/variablesQuery/variablesQuery";
import { RECEIVING_TYPE } from "@/utils/consts";
import { useOutputPlantVariablesContext } from "@/utils/OutputPlantVariableProvider";
import { variableConfigurationExist } from "@/utils/variableConfigValidation";

import DataGrid from "../DataGrid";
import useOutputPlantVariableColumns from "./useOutputPlantVariableColumns";

type OutputVariableTableProps = {
  variableId: string;
  variableName: string;
  type: number;
};

const OutputPlantVariableTable = () => {
  const { organizationId, resourceId } = useParams();

  const { data: resourceVariablesData, isPending } = useGetOutputVariables({
    organizationId,
    resourceId,
    filter: RECEIVING_TYPE.output.toString(),
  });
  const { setOutputPlantVariablesToEdit } = useOutputPlantVariablesContext();

  const mapVariable = (variable: VariableProps): OutputVariableTableProps => {
    return {
      variableId: variable.variableName,
      variableName: variable.variableName,
      type: variable.configuration?.type || 0,
    };
  };

  const columnsDef = useOutputPlantVariableColumns({
    organizationId,
    resourceId,
  });

  const handleUpdateSelectedRows = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    setOutputPlantVariablesToEdit(selectedRows.map((row) => row.variableId));
  };

  const data = useMemo(() => {
    return resourceVariablesData
      ? resourceVariablesData
          .filter((variable) => variableConfigurationExist(variable))
          .map(mapVariable)
      : [];
  }, [resourceVariablesData]);

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columnsDef,
      rowSelection: "multiple",
      onSelectionChanged: handleUpdateSelectedRows,
      suppressRowClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
    }),
    [columnsDef]
  );

  return (
    <DataGrid rowData={data} gridOptions={gridOptions} loading={isPending} />
  );
};

export default OutputPlantVariableTable;
