import React, { createContext, ReactNode, useContext, useState } from "react";

type OutputPlantVariablesContextType = {
  plantOutputVariablesToEdit: string[];
  setOutputPlantVariablesToEdit: (variables: string[]) => void;
};

const OutputPlantVariablesContext = createContext<
  OutputPlantVariablesContextType | undefined
>(undefined);

export const useOutputPlantVariablesContext = () => {
  const context = useContext(OutputPlantVariablesContext);
  if (!context) {
    throw new Error(
      "useOutputPlantVariablesContext must be used within a OutputPlantVariablesProvider"
    );
  }
  return context;
};

type OutputPlantVariablesProviderProps = {
  children: ReactNode;
};

export const OutputPlantVariablesProvider: React.FC<
  OutputPlantVariablesProviderProps
> = ({ children }) => {
  const [plantOutputVariablesToEdit, setOutputPlantVariablesToEdit] = useState<
    string[]
  >([]);

  return (
    <OutputPlantVariablesContext.Provider
      value={{ plantOutputVariablesToEdit, setOutputPlantVariablesToEdit }}
    >
      {children}
    </OutputPlantVariablesContext.Provider>
  );
};
