import { vestResolver } from "@hookform/resolvers/vest";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { LinkRequest } from "@/api/types/commonTypes";
import { VariableProps } from "@/api/variablesSlice/types";
import Button from "@/components/Button";
import { useDialog } from "@/components/Dialog/DialogProvider";
import Input from "@/components/Input";
import {
  useEditVariable,
  useGetVariable,
} from "@/queries/variablesQuery/variablesQuery";
import { RECEIVING_TYPE } from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputNoSlash,
  inputNoWhitespaces,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type EditOutputVariableDialogProps = {
  variableName: string;
} & LinkRequest;

export type EditOutputVariableForm = {
  variable: VariableProps;
};

const EditOutputVariableDialog = ({
  organizationId,
  resourceId,
  variableName,
}: EditOutputVariableDialogProps) => {
  const { mutate } = useEditVariable();
  const [varName, setVarName] = useState<string>(variableName);

  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { data, isPending } = useGetVariable({
    organizationId,
    resourceId,
    variableName: varName,
  });

  let previousVariableName = data?.variableName ?? "";

  const schema = create((data: EditOutputVariableForm) => {
    inputIsNotEmpty("variable.variableName", data.variable.variableName);
    inputNoWhitespaces("variable.variableName", data.variable.variableName);
    inputNoSlash("variable.variableName", data.variable.variableName);
  });

  const methods = useForm<EditOutputVariableForm>({
    defaultValues: {
      variable: {
        variableName: data?.variableName ?? "",
        configuration: {
          type: RECEIVING_TYPE.output,
          expression: data?.configuration?.expression ?? "",
          expressionInput: data?.configuration?.expressionInput ?? {},
          maxAge: data?.configuration?.maxAge ?? 0,
          minLimit: data?.configuration?.minLimit ?? 0,
          maxLimit: data?.configuration?.maxLimit ?? 0,
          invalidMeasurementHandling:
            data?.configuration?.invalidMeasurementHandling ?? 0,
        },
      },
    },
    resolver: vestResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    variableName = data?.variableName ?? "";

    if (data) {
      setValue("variable.variableName", data.variableName);
      previousVariableName = data.variableName;
    }
  }, [data]);

  const onSubmit = (data: EditOutputVariableForm) => {
    const receivingTypeStatus = RECEIVING_TYPE.output;

    const prepareData: VariableProps = {
      variableName: data.variable.variableName,

      configuration: {
        type: receivingTypeStatus,
        expression: data.variable.configuration?.expression ?? "",
        expressionInput: data.variable.configuration?.expressionInput ?? {},
        maxAge: data.variable.configuration?.maxAge ?? 0,
        minLimit: data.variable.configuration?.minLimit ?? 0,
        maxLimit: data.variable.configuration?.maxLimit ?? 0,
        invalidMeasurementHandling:
          data.variable?.configuration?.invalidMeasurementHandling ?? 0,
      },
    };

    mutate({
      organizationId,
      resourceId,
      body: prepareData,
      variableName: previousVariableName,
    });

    setVarName(prepareData.variableName);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="form-edit-output-variable-dialog"
        >
          <div className={styles.formStyles}>
            <h3 data-testid="title-edit-output-variable">
              {t(LangKeys.EDIT_VARIABLE)}
            </h3>

            <Input
              errorText={errors?.variable?.variableName?.message}
              label={t(LangKeys.VARIABLE_NAME)}
              data-testid={`input-output-name`}
              className={styles.inputWrapper}
              {...methods.register(`variable.variableName`)}
            />

            <div className={styles.dialogFooter}>
              <Button
                type="button"
                variant="subtle"
                onClick={hideDialog}
                data-testid="button-cancel-edit-output-variable"
              >
                {t(LangKeys.CANCEL)}
              </Button>

              <Button
                type="submit"
                variant="primary"
                loading={isPending}
                data-testid="button-apply-edit-output-variable"
              >
                {t(LangKeys.APPLY)}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditOutputVariableDialog;
