import { useTranslation } from "react-i18next";

import Button from "@/components/Button";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useDeleteVariables } from "@/queries/variablesQuery/variablesQuery";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

interface DeleteMultipleVariablesDialogProps {
  organizationId: string | undefined;
  resourceId: string | undefined;
  variables: string[];
}

const DeleteMultipleVariablesDialog = ({
  organizationId,
  resourceId,
  variables,
}: DeleteMultipleVariablesDialogProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isPending } = useDeleteVariables();

  const handleDelete = () => {
    mutate({ organizationId, resourceId, variableNames: variables });
  };

  return (
    <>
      <h3>{t(LangKeys.DELETE_VARIABLE)}</h3>

      <p>{t(LangKeys.ARE_YOU_SURE_YOU_WANT_DELETE_THOSE_VARIABLES)}</p>

      {variables.length > 0 && (
        <ul>
          {variables.map((variable) => (
            <li>{variable}</li>
          ))}
        </ul>
      )}

      <div className={styles.dialogFooter}>
        <Button type="button" variant="subtle" onClick={hideDialog}>
          {t(LangKeys.CANCEL)}
        </Button>

        <Button
          type="button"
          variant="primary"
          loading={isPending}
          onClick={() => handleDelete()}
        >
          {t(LangKeys.DELETE)}
        </Button>
      </div>
    </>
  );
};

export default DeleteMultipleVariablesDialog;
