import {
  GridOptions,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  BackButton,
  Button,
  CreateNewResource,
  DataGrid,
  DeleteResource,
  Icon,
  LoadingSpinner,
  ResourceInformation,
} from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useOrganizations } from "@/queries/organizationsQuery/organizationsQuery";
import { useResources } from "@/queries/resourcesQuery/resourcesQuery";
import useBreadcrumbsList from "@/store/useBreadcrumbsList.store";
import useNotificationStore from "@/store/useNotificationStore.store";
import { STATUS_TYPES } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import styles from "./styles.module.scss";
import { useResourcesColumns } from "./useResourcesColumns";

const ResourcesPage = () => {
  const [isActive, setIsActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  const { showDialog } = useDialog();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setBreadcrumbsList } = useBreadcrumbsList();

  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const columns = useResourcesColumns({
    organizationId: organizationId ?? "",
  });

  const {
    data: resourcesData,
    isLoading,
    isError,
  } = useResources(organizationId ?? "");
  const { data: organizationData } = useOrganizations();

  const organizationName = useMemo(() => {
    return organizationData?.find(
      (organization) => organization.id === organizationId
    )?.displayName;
  }, [organizationData, organizationId]);

  useEffect(() => {
    if (organizationName) {
      setBreadcrumbsList([{ label: organizationName, url: "" }]);
    }
  }, [organizationName]);

  useEffect(() => {
    if (resourcesData?.length === 0 && organizationId) {
      showDialog(<CreateNewResource organizationId={organizationId} />);
    }
  }, [resourcesData, organizationId]);

  useEffect(() => {
    if (isError)
      openNotification(t(LangKeys.NOTIFICATION_SOMETHING_WENT_WRONG), "alert");
  }, [isError]);

  const selectedResource = (event: RowDoubleClickedEvent) => {
    const selectedResource = event.data;

    showDialog(
      <ResourceInformation
        resourceId={selectedResource.resourceId ?? ""}
        organizationId={organizationId ?? ""}
      />,
      "large"
    );
  };

  const onAddNewResource = () => {
    if (organizationId)
      showDialog(<CreateNewResource organizationId={organizationId} />);
  };

  const onDeleteButton = () => {
    showDialog(
      <DeleteResource
        resources={selectedRows}
        organizationId={organizationId}
      />,
      "medium"
    );
  };

  const handleUpdateSelectedRows = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows() ?? [];
    setIsActive(selectedRows.length > 0);
    setSelectedRows(selectedRows);
  };

  const navigateToUsersPage = () => {
    navigate(`/organization/${organizationId}/users`);
  };

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: columns,
      rowSelection: "multiple",
      onSelectionChanged: handleUpdateSelectedRows,
      onRowDoubleClicked: selectedResource,
      enableClickSelection: true,
      defaultColDef: {
        resizable: false,
      },
      getRowClass: (params) => {
        const beingProcessed =
          params.data.status === Number(STATUS_KEYS[STATUS_TYPES[1]]) ||
          params.data.status === Number(STATUS_KEYS[STATUS_TYPES[3]]);

        return beingProcessed ? styles.disabled : "";
      },
    }),
    [resourcesData, t, columns]
  );

  const isData = resourcesData && resourcesData.length > 0;

  return (
    <div className={styles.organizationResourcesPage}>
      <h5 className={styles.title}>{t(LangKeys.ORGANIZATION_RESOURCES)}</h5>

      <div>
        <BackButton onClick={() => navigate("/organizations")}>
          {t(LangKeys.BACK)}
        </BackButton>
      </div>

      {isData && (
        <div className={styles.buttonsWrapper}>
          <Button onClick={navigateToUsersPage} variant="primary">
            <Icon className={styles.buttonIcon} name="person" />

            {t(LangKeys.USERS)}
          </Button>

          <div className={styles.rightButtonsBox}>
            <Button
              disabled={!isActive}
              onClick={onDeleteButton}
              className={styles.deletebutton}
              variant="secondary"
              color="red"
            >
              <Icon className={styles.buttonIcon} name="minus" />
              {t(LangKeys.DELETE_RESOURCE)}
            </Button>

            <Button onClick={onAddNewResource}>
              <Icon className={styles.buttonIcon} name="plus" />
              {t(LangKeys.ADD_NEW_RESOURCE)}
            </Button>
          </div>
        </div>
      )}

      {isLoading ? (
        <LoadingSpinner />
      ) : isData ? (
        <DataGrid rowData={resourcesData} gridOptions={gridOptions} />
      ) : (
        <>
          <p className={styles.noResources}>
            {t(LangKeys.NO_RESOURCES_HAS_BEEN_ADDED_YET)}
          </p>

          <div className={styles.createNewOrganizationBox}>
            <Button onClick={onAddNewResource}>
              {t(LangKeys.ADD_NEW_RESOURCE)}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ResourcesPage;
