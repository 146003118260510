import MOIconButton from "@metsooutotec/modes-web-components/dist/react/icon-button";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { CustomFilterProps } from "ag-grid-react";
import { useTranslation } from "react-i18next";

import FilterByList, {
  getFilterOptions,
} from "@/components/DataGrid/GridFilters/FilterByList/FilterByList";
import FilterByString from "@/components/DataGrid/GridFilters/FilterByString/FilterByString";
import FilterByValue from "@/components/DataGrid/GridFilters/FilterByValue/FilterByValue";
import { getReceivingTypeName } from "@/utils/getReceivingTypeName";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { RECEIVING_TYPE } from "../../utils/consts";
import { getRangeName } from "../../utils/getRangeName";
import { useDialog } from "../Dialog/DialogProvider";
import DeleteVariableDialog from "../Forms/DeleteVariableDialog/DeleteVariableDialog";

type PlantVariableColumnsProps = {
  organizationId?: string;
  resourceId?: string;
};

import { CellClassParams } from "ag-grid-community";

const isDisabled = (params: CellClassParams) => {
  return (
    params.data.receivingType === RECEIVING_TYPE.discard ||
    params.data.variableConfig === null
  );
};

const usePlantVariableColumns = ({
  organizationId,
  resourceId,
}: PlantVariableColumnsProps) => {
  const { showDialog } = useDialog();
  const { t } = useTranslation();

  const columnsDef: ColDef[] = [
    {
      field: "variableId",
      hide: true,
    },
    {
      headerName: t(LangKeys.VARIABLE_NAME),
      field: "variableName",
      sortable: true,
      width: 444,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByString onModelChange={onModelChange} {...params} />
      ),
    },
    {
      headerName: t(LangKeys.MIN_VALUE),
      flex: 1,
      field: "minLimit",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
    },
    {
      headerName: t(LangKeys.MAX_VALUE),
      flex: 1,
      field: "maxLimit",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
    },
    {
      headerName: t(LangKeys.IF_OUT_OF_RANGE),
      flex: 1,
      field: "ifOutOfRange",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
      filter: (params: CustomFilterProps) => (
        <FilterByList
          filterOptions={getFilterOptions("IF_OUT_OF_RANGE")}
          {...params}
        />
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return <>{getRangeName(api.data.outOfRange)}</>;
      },
    },
    {
      headerName: t(LangKeys.CALCULATION_TIME_WINDOW),
      flex: 1,
      field: "maxAge",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByValue onModelChange={onModelChange} {...params} />
      ),
    },
    {
      headerName: t(LangKeys.RECEIVING_TYPE),
      flex: 1,
      field: "type",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
      filter: (params: CustomFilterProps) => (
        <FilterByList
          filterOptions={getFilterOptions("RECEIVING_TYPE")}
          {...params}
        />
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <>
            {api.data.variableConfig === null
              ? t(LangKeys.DISCRETE)
              : getReceivingTypeName(api.data.type)}
          </>
        );
      },
    },
    {
      field: "edit",
      width: 80,
      headerComponent: () => (
        <>
          <MOIconButton name="trash" />
        </>
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <>
            <MOIconButton
              name="trash"
              onClick={(e) => {
                e.stopPropagation();
                showDialog(
                  <DeleteVariableDialog
                    organizationId={organizationId}
                    resourceId={resourceId}
                    variableId={`${api.data.variableId}`}
                  />,
                  "small"
                );
              }}
            />
          </>
        );
      },
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
    },
  ];

  return columnsDef;
};

export default usePlantVariableColumns;
