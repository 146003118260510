import { RECEIVING_TYPE } from "@/utils/consts";

import { organizationApiClient } from "../apiClient";
import {
  AddBatchVariablesRequest,
  AddNewVariableRequest,
  DeleteVariableRequest,
  DeleteVariablesRequest,
  EditDerivedVariablesRequest,
  EditVariableRequest,
  GetAllVariablesRequest,
  GetVariableRequest,
  UpdateVariableRequest,
  VariableProps,
} from "./types";

export const getAllVariables = async ({
  organizationId,
  resourceId,
  filter,
}: GetAllVariablesRequest): Promise<VariableProps[]> => {
  const params = new URLSearchParams();
  if (filter) {
    params.append("filter", filter);
  }

  const response = await organizationApiClient.get(
    `organizations/${organizationId}/plants/${resourceId}/variables?${params.toString()}`
  );

  return response.data;
};

export const getOutputVariables = async ({
  organizationId,
  resourceId,
}: GetAllVariablesRequest): Promise<VariableProps[]> => {
  const params = new URLSearchParams();
  params.append("filter", RECEIVING_TYPE.output.toString());

  const response = await organizationApiClient.get(
    `organizations/${organizationId}/plants/${resourceId}/variables?${params.toString()}`
  );

  return response.data;
};

export const addNewVariable = async ({
  organizationId,
  resourceId,
  body,
}: AddNewVariableRequest) => {
  const response = await organizationApiClient.post(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    body
  );

  return response.data;
};

export const editVariablesConfiguration = async ({
  organizationId,
  resourceId,
  body,
}: EditVariableRequest) => {
  const response = await organizationApiClient.put(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    body
  );

  return response.data;
};

export const editDerivedVariablesConfiguration = async ({
  organizationId,
  resourceId,
  body,
}: EditDerivedVariablesRequest) => {
  const response = await organizationApiClient.put(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    body
  );

  return response.data;
};

export const deleteVariable = async ({
  organizationId,
  resourceId,
  variableId,
}: DeleteVariableRequest) => {
  const response = await organizationApiClient.delete(
    `organizations/${organizationId}/plants/${resourceId}/variables/${variableId}`
  );

  return response.data;
};

export const deleteVariables = async ({
  organizationId,
  resourceId,
  variableNames,
}: DeleteVariablesRequest) => {
  const response = await organizationApiClient.delete(
    `organizations/${organizationId}/plants/${resourceId}/variables`,
    {
      data: { variableNames },
    }
  );

  return response.data;
};

export const addBatchVariables = async ({
  organizationId,
  resourceId,
  body,
}: AddBatchVariablesRequest) => {
  const response = await organizationApiClient.post(
    `organizations/${organizationId}/plants/${resourceId}/variables/batch`,
    body
  );

  return response.data;
};

export const updateVariable = async ({
  organizationId,
  resourceId,
  variableName,
  body,
}: UpdateVariableRequest) => {
  const response = await organizationApiClient.put(
    `organizations/${organizationId}/plants/${resourceId}/variables/${variableName}`,
    body
  );

  return response.data;
};

export const getVariable = async ({
  organizationId,
  resourceId,
  variableName,
}: GetVariableRequest) => {
  const response = await organizationApiClient.get(
    `organizations/${organizationId}/plants/${resourceId}/variables/${variableName}`
  );

  return response.data as VariableProps;
};
