import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  addResource,
  deleteResource,
  editResource,
  editResourceConfiguration,
  fetchResourceConfiguration,
  fetchResources,
  fetchResourceStringId,
} from "@/api/resourceSlice/resourceSlice";
import {
  AddNewResource,
  EditResourceRequest,
  Resource,
} from "@/api/resourceSlice/types";
import { LinkRequest } from "@/api/types/commonTypes";
import { useDialog } from "@/components/Dialog/DialogProvider";
import useNotificationStore from "@/store/useNotificationStore.store";
import { INTERVAL_REFETCH, STATUS_TYPES } from "@/utils/consts";
import i18n from "@/utils/i18n/i18n";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import { QUERIES_KEYS } from "../queriesKeys";

type ErrorType = Error & {
  response: {
    data: string;
  };
};

export const useResources = (organizationId: string) => {
  return useQuery<Resource[]>({
    queryKey: [QUERIES_KEYS.resources, { organizationId }],
    queryFn: async () => fetchResources({ organizationId }),
    refetchInterval: INTERVAL_REFETCH,
  });
};

export const useResourceStringId = (
  organizationId: string,
  resourceId: string
) => {
  return useQuery<string>({
    queryKey: [QUERIES_KEYS.resources, { organizationId, resourceId }],
    queryFn: async () => fetchResourceStringId({ organizationId, resourceId }),
  });
};

export const useAddResource = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: async ({ organizationId, body }: AddNewResource) =>
      addResource({ organizationId, body }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.resources] });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_RESOURCE_ADDED_SUCCESS),
        "success"
      );
      hideDialog();
    },
    onError: () => {
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_RESOURCE_UPDATED_FAILED),
        "alert"
      );
    },
  });
};

export const useEditResource = () => {
  const queryClient = useQueryClient();
  const { t } = i18n;
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: async ({ organizationId, body }: EditResourceRequest) =>
      editResource({ organizationId, body }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.resources] });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_RESOURCE_UPDATED_SUCCESS),
        "success"
      );
      hideDialog();
    },
    onError: (error: ErrorType) => {
      openNotification(
        // TODO: (error.response.data.message): Fix type of Errors and make them the same for all error responses from backend
        // @ts-ignore
        error.response.data.message ??
          t(LangKeys.NOTIFICATION_SOMETHING_WENT_WRONG),
        "alert"
      );
    },
  });
};

export const useDeleteResource = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: async ({ organizationId, resourceId }: LinkRequest) =>
      deleteResource({ organizationId, resourceId }),
    onMutate: async ({ organizationId, resourceId }) => {
      const queryKey = [QUERIES_KEYS.resources, { organizationId }];
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous value
      const previousResources = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (oldResources: Resource[]) =>
        oldResources.map((resource) =>
          resource.resourceId === resourceId
            ? { ...resource, status: STATUS_KEYS[STATUS_TYPES[1]] }
            : resource
        )
      );

      // Return a context object with the snapshotted value
      return { previousResources };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.organizations, QUERIES_KEYS.resources],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_RESOURCE_DELETED_SUCCESS),
        "success"
      );
      hideDialog();
    },
    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useFetchResourceConfiguration = ({
  organizationId,
  resourceId,
}: LinkRequest) => {
  return useQuery({
    queryFn: () => fetchResourceConfiguration({ organizationId, resourceId }),
    queryKey: [QUERIES_KEYS.variables, resourceId],
  });
};

export const useEditResourceConfiguration = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: editResourceConfiguration,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.resources],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_RESOURCE_UPDATED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};
