import { useQueryClient } from "@tanstack/react-query";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ModifiedByUser } from "@/api/resourceSlice/types";
import {
  EditResource,
  Icon,
  PlantType,
  StatusComponent,
  TableButton,
} from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { QUERIES_KEYS } from "@/queries/queriesKeys";
import useNotificationStore from "@/store/useNotificationStore.store";
import { RESOURCE_PLANT_TYPE, STATUS_TYPES } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import styles from "./styles.module.scss";

type ResourcesColumnsProps = {
  organizationId?: string;
};

export const useResourcesColumns = ({
  organizationId,
}: ResourcesColumnsProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  const columns: ColDef[] = [
    {
      headerName: t(LangKeys.RESOURCE_NAME),
      field: "displayName",
      cellDataType: "string",
      flex: 1,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
    },
    {
      headerName: t(LangKeys.RESOURCE_ID),
      field: "resourceId",
      flex: 1,
      cellDataType: "string",
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.RESOURCE_TYPE),
      field: "type",
      flex: 1,
      cellDataType: "string",
      sortable: true,
      cellRenderer: (params: { value: keyof typeof RESOURCE_PLANT_TYPE }) => (
        <div className={styles.customCell}>
          <PlantType plantType={params.value} />
        </div>
      ),
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.STATUS),
      field: "status",
      flex: 1,
      cellDataType: "string",
      cellRenderer: (params: { value: keyof typeof STATUS_TYPES }) => (
        <div className={styles.customCell}>
          <StatusComponent status={params.value} />
        </div>
      ),
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.CREATED_AT),
      field: "createdAt",
      flex: 1,
      cellDataType: "date",
      cellRenderer: (params: { data: { createdAt: string } }) =>
        Intl.DateTimeFormat("en-GB").format(new Date(params.data.createdAt)),
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.MODIFIED_AT),
      field: "modifiedAt",
      flex: 1,
      cellDataType: "string",
      cellRenderer: (params: { data: { modifiedAt: string } }) =>
        Intl.DateTimeFormat("en-GB").format(new Date(params.data.modifiedAt)),
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.MODIFIED_BY),
      field: "modifiedByPii",
      flex: 1,
      cellDataType: "string",
      cellRenderer: (params: { data: { modifiedByPii: ModifiedByUser } }) =>
        params.data.modifiedByPii?.fullName,
      cellClass: "cell-style",
    },
    {
      headerName: "Edit",
      headerComponent: () => (
        <div className={styles.tableButtonGroup}>
          <TableButton>
            <Icon name="settings" className={styles.icon} />
          </TableButton>

          <TableButton>
            <Icon name="edit" className={styles.icon} />
          </TableButton>
        </div>
      ),
      field: "editColumn",
      maxWidth: 120,
      cellRenderer: (api: RowClickedEvent) => (
        <div className={styles.tableButtonGroup}>
          <TableButton
            disabled={api.data.type !== 1}
            onClick={(e) => {
              e.stopPropagation();
              if (`${api.data.status}` !== STATUS_KEYS[STATUS_TYPES[2]]) {
                queryClient.invalidateQueries({
                  queryKey: [QUERIES_KEYS.resources],
                });
                openNotification(
                  t(LangKeys.NOTIFICATION_CREATE_NEW_RESOURCE_IN_PROGRESS),
                  "info"
                );

                return;
              }

              navigate(`resource/${api.data.resourceId}`);
            }}
          >
            <Icon name="settings" className={styles.icon} />
          </TableButton>

          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              if (`${api.data.status}` !== STATUS_KEYS[STATUS_TYPES[2]]) {
                queryClient.invalidateQueries({
                  queryKey: [QUERIES_KEYS.resources],
                });
                openNotification(
                  t(LangKeys.NOTIFICATION_CREATE_NEW_RESOURCE_IN_PROGRESS),
                  "info"
                );

                return;
              }

              showDialog(
                <EditResource
                  organizationId={organizationId ?? ""}
                  id={api.data.resourceId}
                  displayName={api.data.displayName}
                  $type={api.data.type}
                />
              );
            }}
          >
            <Icon name="edit" className={styles.icon} />
          </TableButton>
        </div>
      ),
      cellClass: "cell-style",
    },
  ];

  return columns;
};
