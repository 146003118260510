import { vestResolver } from "@hookform/resolvers/vest";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { LightUserData, Project } from "@/api/projectSlice/types";
import { Select, SelectOption } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useProjects } from "@/queries/projectQuery/projectQuery";
import { useUpdateUser } from "@/queries/userQuery/userQuery";
import { inputIsNotEmpty } from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import Button from "../../Button";
import Input from "../../Input";
import styles from "./styles.module.scss";

type EditUserPermissionsProps = {
  organizationId: string;
  userData: LightUserData;
  organizationProjects: Project[];
};

type FormValues = {
  userId: string;
  projects: {
    projectId: string;
    projectName: string;
    roles: { id: string; displayName: string }[];
  }[];
};

const schema = create((data: FormValues) => {
  inputIsNotEmpty("displayName", data.userId);
  data.projects.forEach((project, index) => {
    inputIsNotEmpty(`projects[${index}].projectId`, project.projectId);
  });
});

const EditUserPermissions = ({
  userData,
  organizationProjects,
  organizationId,
}: EditUserPermissionsProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const [isChanged, setIsChanged] = useState(false);

  const { data: orgData } = useProjects(organizationId ?? "");

  const originalUserProjects = useMemo(
    () =>
      orgData?.projects
        .map((project) => ({
          ...project,
          roles: project.roles.filter((role) =>
            role.assignedUsers?.some(
              (assignedUser) => assignedUser.id === userData.id
            )
          ),
        }))
        .filter((project) => project.roles.length > 0) ?? [],
    [orgData, userData.id]
  );

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      userId: userData.id,
      projects: originalUserProjects.map((project) => ({
        projectId: project.id,
        projectName: project.name,
        roles: project.roles.map((role) => ({
          id: role.id,
          displayName: role.displayName,
        })),
      })),
    },
    resolver: vestResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "projects",
    control,
    rules: {
      validate: (value) => {
        if (value.length === 0) {
          return t(LangKeys.SELECT_PROJECT);
        }
        return true;
      },
    },
  });
  const projects = watch("projects");
  const selectedProjectIds = projects.map((field) => field.projectId);

  const { mutate, isPending } = useUpdateUser();

  const handleUpdateUser = async (data: any) => {
    const preparedData = {
      userId: userData.id,
      organizationId,
      projects: data.projects.map((project: any) => ({
        id: project.projectId,
        roles: project.roles.map((role: { id: string }) => role.id),
      })),
    };

    mutate(preparedData);
  };

  const watchedValues = watch();

  useEffect(() => {
    const isFormChanged =
      JSON.stringify(watchedValues) !==
      JSON.stringify({
        userId: userData.id,
        projects: originalUserProjects.map((project) => ({
          projectId: project.id,
          projectName: project.name,
          roles: project.roles.map((role) => ({
            id: role.id,
            displayName: role.displayName,
          })),
        })),
      });

    setIsChanged(isFormChanged);
  }, [watchedValues, userData.id, originalUserProjects]);

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.USER_DETAILS)}</h2>

      <form
        onSubmit={handleSubmit(handleUpdateUser)}
        className={styles.formBox}
      >
        <Input
          label={t(LangKeys.USER_EMAIL)}
          placeholder={userData.emailAddress}
          disabled
        />

        {fields.map((field: any, index: number) => {
          const selectedProjectId = watch(`projects.${index}.projectId`);

          return (
            <div key={field.id} className={styles.projectBox}>
              <Controller
                name={`projects.${index}.projectId`}
                control={control}
                defaultValue={field.projectId || ""}
                render={({ field: fieldHandler }) => (
                  <Select
                    placeholder={t(LangKeys.SELECT_PROJECT)}
                    label={t(LangKeys.PROJECT)}
                    error={!!errors.projects?.[index]?.projectId}
                    errorText={errors.projects?.[index]?.projectId?.message}
                    onMoChange={(event: Event) => {
                      const target = event.target as HTMLInputElement;
                      const id = target.value as string;

                      fieldHandler.onChange(id);
                    }}
                    onMoAfterHide={(e: any) => e.stopPropagation()}
                    value={selectedProjectId}
                  >
                    {organizationProjects?.map((project) => (
                      <SelectOption
                        key={project.id}
                        value={project.id}
                        disabled={selectedProjectIds.includes(project.id)}
                      >
                        {project.name || (
                          <>
                            <b>{t(LangKeys.NO_NAME)}</b> {project.id}
                          </>
                        )}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />

              <Controller
                name={`projects.${index}.roles`}
                control={control}
                defaultValue={field.roles || []}
                render={({ field: fieldHandler }) => {
                  return (
                    <Select
                      multiple
                      disabled={!selectedProjectId}
                      placeholder={t(LangKeys.SELECT_ROLES)}
                      label={t(LangKeys.ROLES)}
                      onMoAfterHide={(e: any) => e.stopPropagation()}
                      error={!!errors.projects?.[index]?.roles}
                      errorText={errors.projects?.[index]?.roles?.message}
                      onMoChange={(e: any) => {
                        const selectedValues = Array.isArray(e.target.value)
                          ? e.target.value
                          : [e.target.value];
                        fieldHandler.onChange(
                          selectedValues.map((id: string) => ({ id }))
                        );
                      }}
                      value={(fieldHandler.value || []).map(
                        (role: any) => role.id
                      )}
                    >
                      {organizationProjects?.flatMap((project) =>
                        project.id === selectedProjectId
                          ? project.roles.map((role) => (
                              <SelectOption key={role.id} value={role.id}>
                                {role.displayName}
                              </SelectOption>
                            ))
                          : []
                      )}
                    </Select>
                  );
                }}
              />

              <Button
                variant="subtle"
                onClick={() => remove(index)}
                className={styles.removeButton}
              >
                {t(LangKeys.REMOVE_PROJECT)}
              </Button>
            </div>
          );
        })}

        <div className={styles.buttonsBox}>
          <Button
            variant="subtle"
            onClick={() =>
              append({
                projectId: "",
                projectName: "",
                roles: [],
              })
            }
          >
            {t(LangKeys.ADD_PROJECT)}
          </Button>

          <Button variant="subtle" onClick={hideDialog}>
            {t(LangKeys.CANCEL)}
          </Button>

          <Button type="submit" loading={isPending} disabled={!isChanged}>
            {t(LangKeys.CONFIRM)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditUserPermissions;
