import { LightUserData, Project } from "@/api/projectSlice/types";

export const mapUsersToProjects = ({
  users,
  projects,
}: {
  users: LightUserData[];
  projects: Project[];
}) => {
  const result: {
    id: string;
    fullName: string;
    emailAddress: string;
    isSuperUser: boolean;
    project: string;
    permissions: any[];
  }[] = [];

  if (!users || !projects) {
    console.error("Users or projects are missing");
    return result;
  }

  projects.forEach((project) => {
    const projectName = project.name;

    project.roles.forEach((role) => {
      const {
        id: permissionId,
        displayName: permissionName,
        assignedUsers,
      } = role;

      assignedUsers.forEach((user) => {
        let userEntry = result.find(
          (entry) =>
            entry.fullName === user.fullName && entry.project === projectName
        );

        if (!userEntry) {
          userEntry = {
            id: user.id,
            fullName: user.fullName,
            emailAddress: user.emailAddress,
            project: projectName,
            permissions: [],
            isSuperUser: user.isSuperUser || false,
          };
          result.push(userEntry);
        }

        userEntry.permissions.push({
          permissionId,
          permissionName,
        });
      });
    });
  });

  users.forEach((user) => {
    const userProjects = result.filter(
      (entry) => entry.fullName === user.fullName
    );

    if (userProjects.length === 0) {
      result.push({
        id: user.id,
        fullName: user.fullName,
        emailAddress: user.emailAddress,
        project: "No Project Assigned",
        permissions: [],
        isSuperUser: user.isSuperUser || false,
      });
    }
  });

  return result;
};

export const calculateRowSpan = (params: any, fieldName: string) => {
  const rowIndex = params.node.rowIndex;
  const displayedRowCount = params.api.getDisplayedRowCount();
  const currentValue = params.data[fieldName];

  if (rowIndex > 0) {
    const prevRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
    if (prevRow && prevRow.data[fieldName] === currentValue) {
      return 0;
    }
  }

  let span = 1;
  for (let i = rowIndex + 1; i < displayedRowCount; i++) {
    const nextRow = params.api.getDisplayedRowAtIndex(i);
    if (nextRow && nextRow.data[fieldName] === currentValue) {
      span++;
    } else {
      break;
    }
  }
  return span;
};
