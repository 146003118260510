import { organizationApiClient } from "../apiClient";
import {
  EmailListProps,
  InviteUser,
  UpdateUserInformation,
  User,
} from "./types";

export const inviteUser = async (body: InviteUser): Promise<User> => {
  const response = await organizationApiClient.post(
    `/adminmanagement/user/invite`,
    body
  );

  return response.data;
};

export const updateUser = async (body: UpdateUserInformation) => {
  const response = await organizationApiClient.put(
    `/adminmanagement/user/put`,
    body
  );

  return response.data;
};

export const emailSearch = async (
  emailSearchValue: string
): Promise<EmailListProps[]> => {
  const response = await organizationApiClient.get(
    `/adminmanagement/user/search?emailSearch=${emailSearchValue}`
  );

  return response.data;
};

export const deleteUser = async (
  body: { userId: string; userEmail: string },
  organizationId: string
): Promise<any> => {
  const response = await organizationApiClient.delete(
    `/adminmanagement/user/delete/${organizationId}`,
    { data: body }
  );

  return response.data;
};

export const setSuperUser = async (body: {
  organizationId: string;
  userId: string;
  isSuperUser: boolean;
}): Promise<any> => {
  const response = await organizationApiClient.put(
    `/adminmanagement/user/superuseraccess`,
    body
  );
  return response.data;
};
