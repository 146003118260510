import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { localStorageUtils } from "../localStorageUtils";
import en_form from "./locales/en/en_form.json";
import en_hints from "./locales/en/en_hints.json";
import en_main from "./locales/en/en_main.json";
import en_notifications from "./locales/en/en_notifications.json";
import fi_form from "./locales/fi/fi_form.json";
import fi_hints from "./locales/fi/fi_hints.json";
import fi_main from "./locales/fi/fi_main.json";
import fi_notifications from "./locales/fi/fi_notifications.json";

export type Languages = "en" | "fi";

export const languagesShorts = {
  en: "English",
  fi: "Suomi",
};

const english = {
  ...en_main,
  ...en_hints,
  ...en_form,
  ...en_notifications,
};

const finnish = {
  ...fi_main,
  ...fi_hints,
  ...fi_form,
  ...fi_notifications,
};

const resources = {
  en: { translation: english },
  fi: { translation: finnish },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorageUtils.getLanguageSelection() || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
