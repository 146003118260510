import { vestResolver } from "@hookform/resolvers/vest";
import { useRef } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LinkRequest } from "@/api/types/commonTypes";
import { AddVariable } from "@/api/variablesSlice/types";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import Input from "@/components/Input";
import { useAddVariablesBatch } from "@/queries/variablesQuery/variablesQuery";
import { RECEIVING_TYPE } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { useDialog } from "../../Dialog/DialogProvider";
import styles from "./styles.module.scss";
import {
  AddNewVariablesBatchPropsForm,
  AddNewVariablesPropsForm,
} from "./types";
import schema from "./validationSchema";

// Currently, this component supports adding only output variables
const AddNewVariables = ({ organizationId, resourceId }: LinkRequest) => {
  const { hideDialog } = useDialog();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { mutate, isPending } = useAddVariablesBatch();
  const { t } = useTranslation();

  const methods = useForm<AddNewVariablesBatchPropsForm>({
    mode: "onSubmit",
    defaultValues: {
      variables: [
        {
          variableName: "",
          minLimit: "",
          maxLimit: "",
          outOfRange: "",
          type: RECEIVING_TYPE.output.toString(),
        },
      ],
    },
    resolver: vestResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    name: "variables",
    control: control,
  });

  const handleAdd = () => {
    append(
      {
        variableName: "",
        minLimit: "",
        maxLimit: "",
        outOfRange: "",
        type: RECEIVING_TYPE.output.toString(),
        averaging: "",
        duration: "",
        durationType: "",
      },
      { shouldFocus: false }
    );

    // Focus the last added input field AFTER React updates the DOM
    setTimeout(() => {
      const lastIndex = inputRefs.current.length - 1;
      if (inputRefs.current[lastIndex]) {
        inputRefs.current[lastIndex]!.focus();
      }
    }, 100);
  };

  const sendData = (data: AddNewVariablesBatchPropsForm) => {
    const mappedVariablesData: AddVariable[] = data.variables.map(
      (variable: AddNewVariablesPropsForm) => ({
        variableName: variable.variableName,
        configuration: {
          maxAge: 0,
          minLimit: Number(variable.minLimit),
          maxLimit: Number(variable.maxLimit),
          invalidMeasurementHandling: Number(variable.outOfRange),
          type: Number(variable.type),
          // These fields are used for derived variables and they are not created here
          expression: "",
          expressionInput: {},
        },
      })
    );

    const variablesList = {
      variables: mappedVariablesData,
    };

    mutate({
      organizationId,
      resourceId,
      body: variablesList,
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(sendData)();
        }}
        data-testid="form-add-new-variables-batch"
      >
        <div className={styles.formStyles}>
          <h3 data-testid="title-add-variable-batch">
            {t(LangKeys.ADD_PLANT_VARIABLES_BATCH)}
          </h3>

          {fields.map((field, index) => {
            return (
              <div key={field.id} className={styles.formStyles}>
                <Input
                  errorText={errors.variables?.[index]?.variableName?.message}
                  label={t(LangKeys.VARIABLE_NAME)}
                  data-testid={`input-variable-name-${index}`}
                  className={styles.inputWrapper}
                  {...methods.register(`variables.${index}.variableName`)}
                />

                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    remove(index);
                  }}
                  className={styles.removeButton}
                  data-testid="button-remove-variable"
                >
                  <Icon slot="prefix" name="trash"></Icon>
                </Button>

                <hr className={styles.divider}></hr>
              </div>
            );
          })}

          <div className={styles.dialogFooter}>
            <>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  handleAdd();
                }}
                data-testid="button-apply-add-variables"
              >
                <Icon slot="prefix" name="plus"></Icon>
                {t(LangKeys.ADD_VARIABLE)}
              </Button>
            </>

            <>
              <Button
                type="button"
                variant="subtle"
                onClick={hideDialog}
                data-testid="button-cancel-add-variables"
              >
                {t(LangKeys.CANCEL)}
              </Button>

              <Button
                type="submit"
                variant="primary"
                loading={isPending}
                data-testid="button-apply-apply-variables"
                disabled={fields.length === 0}
              >
                {t(LangKeys.APPLY)}
              </Button>
            </>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddNewVariables;
