type OutputPlantVariableColumnsProps = {
  organizationId?: string;
  resourceId?: string;
};

import { MOIconButton } from "@metsooutotec/modes-web-components/dist/react";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { CustomFilterProps } from "ag-grid-react";
import { useTranslation } from "react-i18next";

import { getReceivingTypeName } from "@/utils/getReceivingTypeName";
import { LangKeys } from "@/utils/i18n/languageKeys";

import FilterByList, {
  getFilterOptions,
} from "../DataGrid/GridFilters/FilterByList/FilterByList";
import FilterByString from "../DataGrid/GridFilters/FilterByString/FilterByString";
import { useDialog } from "../Dialog/DialogProvider";
import DeleteVariableDialog from "../Forms/DeleteVariableDialog";
import EditOutputVariableDialog from "../Forms/EditOutputVariableDialog/EditOutputVariableDialog";

const useOutputPlantVariableColumns = ({
  organizationId,
  resourceId,
}: OutputPlantVariableColumnsProps) => {
  const { showDialog } = useDialog();
  const { t } = useTranslation();

  const columnsDef: ColDef[] = [
    {
      field: "variableId",
      hide: true,
    },
    {
      headerName: "Variable",
      field: "variableName",
      sortable: true,
      width: 444,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByString onModelChange={onModelChange} {...params} />
      ),
    },
    {
      headerName: t(LangKeys.RECEIVING_TYPE),
      flex: 1,
      field: "type",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      filter: (params: CustomFilterProps) => (
        <FilterByList
          filterOptions={getFilterOptions("RECEIVING_TYPE")}
          {...params}
        />
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <>
            {api.data.variableConfig === null
              ? t(LangKeys.DISCRETE)
              : getReceivingTypeName(api.data.type)}
          </>
        );
      },
    },
    {
      field: "edit",
      width: 80,
      headerComponent: () => (
        <>
          <MOIconButton name="edit" />
        </>
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <>
            <MOIconButton
              name="edit"
              onClick={(e) => {
                e.stopPropagation();
                showDialog(
                  <EditOutputVariableDialog
                    organizationId={organizationId}
                    resourceId={resourceId}
                    variableName={`${api.data.variableName}`}
                  />,
                  "small"
                );
              }}
            />
          </>
        );
      },
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
    },
    {
      field: "edit",
      width: 80,
      headerComponent: () => (
        <>
          <MOIconButton name="trash" />
        </>
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <>
            <MOIconButton
              name="trash"
              onClick={(e) => {
                e.stopPropagation();
                showDialog(
                  <DeleteVariableDialog
                    organizationId={organizationId}
                    resourceId={resourceId}
                    variableId={`${api.data.variableId}`}
                  />,
                  "small"
                );
              }}
            />
          </>
        );
      },
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
    },
  ];

  return columnsDef;
};

export default useOutputPlantVariableColumns;
