import { vestResolver } from "@hookform/resolvers/vest";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { ResourceConfigurationProps } from "@/api/resourceSlice/types";
import { Button, Icon, Input } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import {
  useEditResourceConfiguration,
  useFetchResourceConfiguration,
} from "@/queries/resourcesQuery/resourcesQuery";
import {
  AveragingTypesList,
  DurationTypesList,
  IfOutOfRangeList,
} from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputMustBeEqualOrMoreThan,
  inputMustBeNumber,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { calculateSeconds } from "@/utils/utils";

import styles from "./styles.module.scss";

type EditResourceConfigurationProps = {
  organizationId?: string;
  resourceId?: string;
};

type EditResourceDialogFormData = {
  minLimit?: number;
  maxLimit?: number;
  invalidMeasurementHandling?: string;
  averaging?: string;
  duration?: number;
  durationType?: string;
};

const EditResourceConfiguration = ({
  organizationId,
  resourceId,
}: EditResourceConfigurationProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();

  const { data: configurationData, isLoading } = useFetchResourceConfiguration({
    organizationId,
    resourceId,
  });

  const { mutate, isPending } = useEditResourceConfiguration();

  const schema = create((data: EditResourceDialogFormData) => {
    inputIsNotEmpty("minLimit", data.minLimit ?? "");
    inputIsNotEmpty("maxLimit", data.maxLimit ?? "");
    inputIsNotEmpty("outOfRange", data.invalidMeasurementHandling ?? "");
    inputMustBeNumber("duration", data.duration ?? "");
    inputMustBeEqualOrMoreThan("duration", data.duration ?? 0, 0);
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<EditResourceDialogFormData>({
    defaultValues: {
      minLimit: configurationData?.defaultVariableConfiguration.minLimit,
      maxLimit: configurationData?.defaultVariableConfiguration.maxLimit,
      invalidMeasurementHandling: `${configurationData?.defaultVariableConfiguration.invalidMeasurementHandling}`,
    },
    resolver: vestResolver(schema),
  });

  useEffect(() => {
    if (configurationData) {
      reset({
        minLimit: configurationData.defaultVariableConfiguration.minLimit,
        maxLimit: configurationData.defaultVariableConfiguration.maxLimit,
        invalidMeasurementHandling: `${configurationData.defaultVariableConfiguration.invalidMeasurementHandling}`,
        duration: configurationData.defaultVariableConfiguration.maxAge,
      });
    }
  }, [configurationData, reset]);

  const submitHandler = (data: EditResourceDialogFormData) => {
    if (data.maxLimit && data.minLimit && data.maxLimit < data.minLimit) {
      setError("maxLimit", {
        type: "manual",
        message: t(LangKeys.VALIDATION_EQUAL_OR_MORE_THAN, {
          value: data.minLimit,
        }),
      });

      return;
    }

    const maxAgeValue = calculateSeconds(
      Number(data.duration),
      data.durationType
    );

    const prepareData: ResourceConfigurationProps = {
      maxClockSkew: configurationData?.maxClockSkew,
      defaultVariableConfiguration: {
        maxLimit: data.maxLimit ?? 0,
        minLimit: data.minLimit ?? 0,
        invalidMeasurementHandling: Number(data.invalidMeasurementHandling),
        expression:
          configurationData?.defaultVariableConfiguration?.expression ?? "",
        expressionInput:
          configurationData?.defaultVariableConfiguration?.expressionInput ??
          "",
        maxAge: maxAgeValue,
        type: configurationData?.defaultVariableConfiguration?.type ?? 0,
      },
      variableConfigurations: configurationData?.variableConfigurations,
    };

    mutate({
      organizationId,
      resourceId,
      body: prepareData,
    });
  };

  return (
    <div>
      <h3 className={styles.dialogHeader}>{t(LangKeys.EDIT_CONFIGURATION)}</h3>

      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={styles.formStyles}>
          <div className={styles.twoInputsWrapper}>
            <div className={styles.inputWrapper}>
              <Input
                errorText={errors.minLimit?.message}
                label={t(LangKeys.MIN_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                {...register("minLimit")}
              />
            </div>

            <div className={styles.inputWrapper}>
              <Input
                errorText={errors.maxLimit?.message}
                label={t(LangKeys.MAX_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                {...register("maxLimit")}
              />
            </div>
          </div>

          <MOSelect
            errorText={errors.invalidMeasurementHandling?.message}
            disabled={isLoading}
            label={t(LangKeys.IF_OUT_OF_RANGE)}
            placeholder={t(LangKeys.CONDITION)}
            placement="bottom"
            onMoAfterHide={(e: any) => e.stopPropagation()}
            {...register("invalidMeasurementHandling")}
          >
            {IfOutOfRangeList.map((val) => (
              <MOOption key={val.value} value={`${val.value}`}>
                {val.name}
              </MOOption>
            ))}
          </MOSelect>

          <MOSelect
            label={t(LangKeys.AVERAGING)}
            errorText={errors.averaging?.message}
            placeholder={t(LangKeys.SELECT_AVERAGING_TYPE)}
            placement="bottom"
            onMoAfterHide={(e: any) => e.stopPropagation()}
            {...register("averaging")}
            // TODO in future
            // For now can be select only one type of averaging
            // TASK 1. point: https://metso.atlassian.net/jira/software/c/projects/DTWIN/boards/2497?assignee=712020%3Aa41cff2d-d2c6-4684-b062-c72264faabba&quickFilter=13358&selectedIssue=DTWIN-6235
            value={AveragingTypesList[0].value} // time-based-averaging
            disabled
          >
            {AveragingTypesList.map((item) => (
              <MOOption key={item.value} value={item.value}>
                {item.name}
              </MOOption>
            ))}
          </MOSelect>

          <div className={styles.durationOuterWrapper}>
            <div className={styles.durationWrapper}>
              <Input
                error={errors.duration !== undefined}
                label={t(LangKeys.DURATION)}
                placeholder={t(LangKeys.VALUE)}
                {...register("duration")}
              />

              <Icon name="arrow-right" className={styles.icon} />

              <MOSelect
                placement="bottom"
                placeholder={t(LangKeys.SELECT_DURATION_TYPE)}
                onMoAfterHide={(e: any) => e.stopPropagation()}
                {...register("durationType")}
                value={DurationTypesList[0].value} // seconds
              >
                {DurationTypesList.map((item) => (
                  <MOOption key={item.value} value={item.value}>
                    {item.name}
                  </MOOption>
                ))}
              </MOSelect>
            </div>

            {errors.duration && (
              <p className={styles.errorText}>
                {typeof errors?.duration?.message === "string"
                  ? errors.duration.message
                  : ""}
              </p>
            )}
          </div>
        </div>

        <div className={styles.dialogFooter}>
          <Button type="button" variant="subtle" onClick={() => hideDialog()}>
            {t(LangKeys.CANCEL)}
          </Button>

          <Button type="submit" variant="primary" loading={isPending}>
            {t(LangKeys.APPLY)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditResourceConfiguration;
