import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Resource } from "@/api/resourceSlice/types";
import Button from "@/components/Button";
import { QUERIES_KEYS } from "@/queries/queriesKeys";
import { useDeleteResource } from "@/queries/resourcesQuery/resourcesQuery";
import useNotificationStore from "@/store/useNotificationStore.store";
import { STATUS_TYPES } from "@/utils/consts";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { STATUS_KEYS } from "@/utils/utils";

import { useDialog } from "../../Dialog/DialogProvider";
import styles from "./deleteresource.module.scss";

type DeleteOrganizationForm = {
  resources: Resource[];
  organizationId: string | undefined;
};

const DeleteResource = ({
  resources = [],
  organizationId,
}: DeleteOrganizationForm) => {
  const queryClient = useQueryClient();
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const { mutate, isSuccess, isPending } = useDeleteResource();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  const { handleSubmit } = useForm<DeleteOrganizationForm>({
    defaultValues: {
      resources,
    },
  });

  const resourcesToDelete = resources.map((resource) => {
    return {
      organizationId: organizationId ?? "",
      resourceId: resource.resourceId,
      status: resource.status,
    };
  });

  const handleDeleteOrganization = () => {
    if (!organizationId) return;

    resourcesToDelete.forEach((resource) => {
      if (
        resource.status !== Number(STATUS_KEYS[STATUS_TYPES[2]]) &&
        resource.status !== Number(STATUS_KEYS[STATUS_TYPES[9]])
      ) {
        queryClient.invalidateQueries({
          queryKey: [QUERIES_KEYS.organizations],
        });
        openNotification(
          t(LangKeys.NOTIFICATION_CANNNOT_DELETE_THIS_RESOURCE),
          "info"
        );

        return;
      }

      mutate({ organizationId, resourceId: resource.resourceId });
    });
  };

  useEffect(() => {
    if (isSuccess) {
      hideDialog();
    }
  }, [isSuccess, hideDialog]);

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.DELETE_RESOURCE)}</h2>

      <p>{t(LangKeys.RESOURCE_DELETE_CONFIRMATION_TEXT)}</p>

      <div className={styles.buttonsBox}>
        <Button
          variant="subtle"
          className={styles.buttons}
          onClick={hideDialog}
        >
          {t(LangKeys.CANCEL)}
        </Button>

        <Button
          className={styles.buttons}
          type="submit"
          loading={isPending}
          onClick={handleSubmit(handleDeleteOrganization)}
        >
          {t(LangKeys.CONFIRM)}
        </Button>
      </div>
    </div>
  );
};

export default DeleteResource;
