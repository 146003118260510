import { useTranslation } from "react-i18next";

import { Button, Icon } from "@/components";
import CopyButtonInput from "@/components/CopyButtonInput/CopyButtonInput";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useResourceStringId } from "@/queries/resourcesQuery/resourcesQuery";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./resourceinformation.module.scss";

type ResourceInformationProps = {
  resourceId: string;
  organizationId: string;
};

const ResourceInformation = ({
  resourceId,
  organizationId,
}: ResourceInformationProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();

  const {
    data: resourcesDataStringId,
    isPending,
    isError,
  } = useResourceStringId(organizationId, resourceId);

  return (
    <div>
      <div className={styles.modalHeader}>
        <h2 className={styles.header}>{t(LangKeys.RESOURCE_INFORMATION)}</h2>

        <Button
          variant="subtle"
          onClick={hideDialog}
          className={styles.closeButton}
        >
          <Icon
            className={styles.closeIcon}
            name="cross"
            onClick={hideDialog}
          />
        </Button>
      </div>

      <div className={styles.formBox}>
        <CopyButtonInput label={t(LangKeys.RESOURCE_ID)} value={resourceId} />
        <CopyButtonInput
          label={t(LangKeys.ORGANIZATION_ID)}
          value={organizationId}
        />
        <CopyButtonInput
          label={t(LangKeys.CONNECTION_STRING)}
          value={resourcesDataStringId ?? t(LangKeys.NO_CONNECTION_STRING)}
          isLoading={isPending}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default ResourceInformation;
