import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  GetAllVariablesRequest,
  GetVariableRequest,
} from "@/api/variablesSlice/types";
import {
  addBatchVariables,
  addNewVariable,
  deleteVariable,
  deleteVariables,
  editDerivedVariablesConfiguration,
  getAllVariables,
  getOutputVariables,
  getVariable,
  updateVariable,
} from "@/api/variablesSlice/variableSlice";
import { useDialog } from "@/components/Dialog/DialogProvider";
import useNotificationStore from "@/store/useNotificationStore.store";
import { RECEIVING_TYPE } from "@/utils/consts";
import i18n from "@/utils/i18n/i18n";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { QUERIES_KEYS } from "../queriesKeys";

type ErrorType = Error & {
  response: {
    data: string;
  };
};

export const useGetVariables = ({
  organizationId,
  resourceId,
  filter,
}: GetAllVariablesRequest) => {
  return useQuery({
    queryKey: [QUERIES_KEYS.variables, organizationId, resourceId, filter],
    queryFn: () => getAllVariables({ organizationId, resourceId, filter }),
  });
};

export const useGetOutputVariables = ({
  organizationId,
  resourceId,
}: GetAllVariablesRequest) => {
  return useQuery({
    queryKey: [
      QUERIES_KEYS.variables,
      organizationId,
      resourceId,
      RECEIVING_TYPE.output,
    ],
    queryFn: () =>
      getOutputVariables({
        organizationId,
        resourceId,
        filter: RECEIVING_TYPE.output.toString(),
      }),
  });
};

export const useGetVariable = ({
  organizationId,
  resourceId,
  variableName,
}: GetVariableRequest) => {
  return useQuery({
    queryKey: [
      QUERIES_KEYS.variables,
      organizationId,
      resourceId,
      variableName,
    ],
    queryFn: () => getVariable({ organizationId, resourceId, variableName }),
  });
};

export const useAddVariable = () => {
  const { hideDialog } = useDialog();
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: addNewVariable,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_ADDED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useEditVariablesConfiguration = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: editDerivedVariablesConfiguration,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_EDIT_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useDeleteVariable = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: deleteVariable,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_DELETED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useDeleteVariables = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: deleteVariables,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_DELETED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useAddVariablesBatch = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: addBatchVariables,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_ADDED_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};

export const useEditVariable = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );
  const { hideDialog } = useDialog();

  return useMutation({
    mutationFn: updateVariable,
    mutationKey: [QUERIES_KEYS.variables],

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_KEYS.variables],
      });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_VARIABLE_EDIT_SUCCESS),
        "success"
      );
      hideDialog();
    },

    onError: (error: ErrorType) => {
      openNotification(error?.response?.data, "alert");
    },
  });
};
